import moment from "moment"
import 'moment-duration-format'

export const formattedDuration = (start_time, end_time) => {
    if (!end_time || !start_time) return '-'

    end_time = Date.parse(end_time)
    start_time = Date.parse(start_time)

    const duration = moment.duration(end_time - start_time)
    const daysCount = Math.floor(duration.asDays())

    return `${daysCount ? daysCount + 'J ' : ''}${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`
}

export const randomInt = (min, max) => Math.floor(Math.random() * (max - min) + min)

export const matchRoles = (userRoles, authRoles) => {
    if (!authRoles || authRoles.length === 0) return true
    if (!userRoles) return false

    return userRoles.filter(role => authRoles.includes(role)).length > 0
}